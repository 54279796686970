import { EnvironmentProviders } from '@angular/core';

import { ClinicSuppliersPermissions } from '@mp/supplier-management/clinic-suppliers/feature';
import { PlatformSuppliersPermissions } from '@mp/supplier-management/platform-suppliers/feature';
import { provideModulePermissions } from '@mpk/shared/data-access';

// There is no SupplierManagement module yet in the BE, hence the permissions belong to ContentManager.
const moduleName = 'ContentManager';

export function provideSupplierManagementFeature(): EnvironmentProviders[] {
  return [provideModulePermissions(moduleName, ClinicSuppliersPermissions, PlatformSuppliersPermissions)];
}
